import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalPipe } from './decimal/decimal.pipe';


@NgModule({
  declarations: [DecimalPipe],
  exports: [ DecimalPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
