import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



import 'style-loader!angular2-toaster/toaster.css';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';


@Injectable({
  providedIn: 'root',
})
export class ServicioService {
  // API_Uri = 'http://localhost:8888/Angular/elespino/API_REST'; 
  // API_Uri = 'https://elespino.clanbolivia.com/API_REST';
  API_Uri = 'https://clanbolivia.online/espino/API_REST';

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  sesion = [];
  usuario: any;

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
  ) { }
  setUsuario(obj) {
    this.usuario = obj;
  }
  getUsuario() {
    return this.usuario;
  }
  guardarSesion(sesion) {
    this.sesion = sesion;
    sessionStorage.setItem('currentUser', JSON.stringify(sesion));
    // localStorage.clear();
  }
  recuperarSesion() {
    console.log(1);
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }
////////////////////////////////////////////////////////
// WS
// EC00ADM - POST - LOGIN DE USUARIO
getLoginUsuario(user: any, password: any) {
  return this.http.get(`${this.API_Uri}/getLoginUsuario/${user}/${password}`);
}

// EC01ADM - GET - OBTENER LISTA DE TIPOS DE USUARIO
getAllTipoUsuario(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllTipoUsuario/${idtoken}/${token}`);
}

// EC02ADM - POST - INSERTAR TIPO DE USUARIO
postInsertarTipoUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarTipoUsuario` , obj);
}

// EC03ADM - POST - MODIFICAR TIPO DE USUARIO
postModificarTipoUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarTipoUsuario` , obj);
}

// EC04ADM - POST - ELIMINAR TIPO DE USUARIO
postEliminarTipoUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarTipoUsuario` , obj);
}

// EC05ADM - GET - OBTENER LISTA DE USUARIOS
getAllUsuario(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllUsuario/${idtoken}/${token}`);
}

// EC06ADM - POST - INSERTAR USUARIO
postInsertarUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarUsuario` , obj);
}

// EC07ADM - POST - MODIFICAR USUARIO
postModificarUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarUsuario` , obj);
}

// EC08ADM - POST - ELIMINAR USUARIO
postEliminarUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarUsuario` , obj);
}

// EC09ADM - GET - OBTENER LISTA DE ÚLTIMOS 20 CLIENTES
getLast20Cliente(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getLast20Cliente/${idtoken}/${token}`);
}

// EC10ADM - GET - OBTENER LISTA DE TODOS CLIENTES
getAllCliente(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllCliente/${idtoken}/${token}`);
}

// EC11ADM - GET - BUSCAR CLIENTES POR NOMBRE
getBuscarCliente(nombre: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getBuscarCliente/${nombre}/${idtoken}/${token}`);
}

// EC12ADM - POST - RESETEAR PASSWORD DE CLIENTE
postResetPasswordCliente(obj: any) {
  return this.http.post(`${this.API_Uri}/postResetPasswordCliente` , obj);
}

// EC13ADM - GET - OBTENER LISTA DE TODAS LAS MARCAS
getAllMarca(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllMarca/${idtoken}/${token}`);
}

// EC14ADM - POST - INSERTAR MARCA
postInsertarMarca(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarMarca` , obj);
}

// EC15ADM - POST - MODIFICAR MARCA
postModificarMarca(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarMarca` , obj);
}

// EC16ADM - POST - ELIMINAR MARCA (VERIFICAR SI NO FUE ASIGNADA)
postEliminarMarca(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarMarca` , obj);
}

// EC17ADM - GET - OBTENER LISTA DE TODAS LAS CATEGORIAS
getAllCategoria(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllCategoria/${idtoken}/${token}`);
}

// EC18ADM - POST - INSERTAR CATEGORIA
postInsertarCategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarCategoria` , obj);
}

// EC19ADM - POST - MODIFICAR CATEGORIA
postModificarCategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarCategoria` , obj);
}

// EC20ADM - POST - ELIMINAR CATEGORIA (VERIFICAR SI NO FUE ASIGNADA)
postEliminarCategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarCategoria` , obj);
}

// EC21ADM - GET - OBTENER LISTA DE TODOS LOS PRODUCTOS
getAllProducto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllProducto/${idtoken}/${token}`);
}

// EC22ADM - POST - INSERTAR PRODUCTO
postInsertarProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarProducto` , obj);
}

// EC23ADM - POST - MODIFICAR PRODUCTO
postModificarProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarProducto` , obj);
}

// EC24ADM - POST - ELIMINAR PRODUCTO
postEliminarProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarProducto` , obj);
}

// EC25ADM - GET - OBTENER LISTA DE PÁGINAS
getListaPaginas(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPaginas/${idtoken}/${token}`);
}

// EC26ADM - GET - OBTENER LISTA DE TIPOS DE USUARIO Y PERMISOS
getTipoUsuarioPermisos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getTipoUsuarioPermisos/${idtoken}/${token}`);
}

// EC27ADM - GET - OBTENER LISTA DE CUPONES ACTIVOS
getListaCuponesActivos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaCuponesActivos/${idtoken}/${token}`);
}

// EC28ADM - GET - OBTENER LISTA DE CUPONES POR RANGO DE FECHAS
getListaCuponesRango(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaCuponesRango/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}

// EC29ADM - POST - INSERTAR CUPON
postInsertarCupon(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarCupon` , obj);
}

// EC30ADM - POST - MODIFICAR CUPON
postModificarCupon(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarCupon` , obj);
}

// EC31ADM - POST - ELIMINAR CUPON
postEliminarCupon(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarCupon` , obj);
}

// EC32ADM - GET - OBTENER LISTA DE NOMBRES DE PRODUCTOS
getListaProducto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaProducto/${idtoken}/${token}`);
}

// EC33ADM - GET - OBTENER LISTA DE TIPOS DE CUPON
getListaTipoCupon(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaTipoCupon/${idtoken}/${token}`);
}

// EC34ADM - GET - OBTENER LISTA DE PEDIDOS POR ESTADO
getListaPedidosEstado(estado: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPedidosEstado/${estado}/${idtoken}/${token}`);
}

// EC35ADM - GET - OBTENER DETALLE DE PEDIDO
getDetallePedido(idPedido: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getDetallePedido/${idPedido}/${idtoken}/${token}`);
}

// EC36ADM - POST - MODIFICAR ESTADO DE PEDIDO
postModificarEstadoPedido(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarEstadoPedido` , obj);
}

// EC37ADM - POST - MODIFICAR FECHA, HORA Y ESTADO DE PEDIDO
postModificarFechaHoraPedido(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarFechaHoraPedido` , obj);
}

// EC38ADM - GET - OBTENER CANTIDADES DE PEDIDOS SEGUN ESTADO
getCantidadPedidosEstado(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getCantidadPedidosEstado/${idtoken}/${token}`);
}

// EC39ADM - GET - OBTENER LISTA DE IMAGENES DE PRODUCTO
getListaImagenesProducto(idProducto: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaImagenesProducto/${idProducto}/${idtoken}/${token}`);
}

// EC40ADM - POST - ELIMINAR PRODUCTO IMAGEN
postEliminarProductoImagen(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarProductoImagen` , obj);
}

// EC41ADM - GET - OBTENER LISTA DE MARCAS
getListaMarca(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaMarca/${idtoken}/${token}`);
}

// EC42ADM - POST - INSERTAR IMAGEN PRODUCTO
postInsertarImagenProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarImagenProducto` , obj);
}

// EC43ADM - GET - OBTENER LISTA DE TODOS LOS PEDIDOS
getListaPedidos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPedidos/${idtoken}/${token}`);
}

// EC44ADM - GET - OBTENER LISTA DE PEDIDOS POR RANGO DE FECHAS
getListaPedidosRango(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPedidosRango/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}

// EC45ADM - GET - OBTENER LISTA DE PEDIDOS POR CLIENTE
getListaPedidosCliente(nombre: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPedidosCliente/${nombre}/${idtoken}/${token}`);
}

// EC46ADM - GET - OBTENER LISTA DE LOS ÚLTIMOS 20 PEDIDOS
getLast20Pedidos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getLast20Pedidos/${idtoken}/${token}`);
}

// EC47PRO - POST - MODIFICAR PASSWORD PARA USUARIO
postModificarPasswordUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarPasswordUsuario` , obj);
}

// EC48ADM - GET - OBTENER LISTA DE PEDIDOS SEGUN MARCA
getListaPedidosMarca(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPedidosMarca/${idtoken}/${token}`);
}

// EC49ADM - GET - OBTENER DETALLE DE PEDIDO SEGÚN MARCA
getDetallePedidoMarca(idPedido: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getDetallePedidoMarca/${idPedido}/${idtoken}/${token}`);
}

// EC50ADM - POST - MODIFICAR ESTADO DETALLE PEDIDO
postModificarEstadoDetallePedido(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarEstadoDetallePedido` , obj);
}

// EC51ADM - GET - OBTENER LISTA DE PRODUCTOS MAS VENDIDOS DE LOS ULTIMOS 30 DIAS
getProductosMasVendidos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getProductosMasVendidos/${idtoken}/${token}`);
}

// EC52ADM - GET - OBTENER LISTA DE CLIENTES REGISTRADOS RECIENTEMENTE
getLastClientes(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getLastClientes/${idtoken}/${token}`);
}

// EC53ADM - GET - OBTENER CANTIDADES PARA CARDS
getCantidades(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getCantidades/${idtoken}/${token}`);
}

getCantidadesPanel() {
  return this.http.get(`${this.API_Uri}/getCantidadesPanel`);
}


// EC54ADM - GET - OBTENER LISTA DE CUPONES ACTIVOS Y CANTIDAD UTILIZADA
getListaCuponesCantidad(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaCuponesCantidad/${idtoken}/${token}`);
}

// EC55ADM - GET - OBTENER LISTA DE SLIDER
getAllSlider(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllSlider/${idtoken}/${token}`);
}

// EC56ADM - POST - INSERTAR SLIDER
postInsertarSlider(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarSlider` , obj);
}

// EC57ADM - POST - MODIFICAR SLIDER
postModificarSlider(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarSlider` , obj);
}

// EC58ADM - POST - ELIMINAR SLIDER
postEliminarSlider(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarSlider` , obj);
}

// EC59ADM - GET - OBTENER HORARIO DE MARCA
getHorarioMarca(idMarca: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getHorarioMarca/${idMarca}/${idtoken}/${token}`);
}

// EC60ADM - POST - INSERTAR HORARIO
postInsertarHorario(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarHorario` , obj);
}

// EC61ADM - GET - OBTENER LISTA DE DIAS
getListaDias() {
  return this.http.get(`${this.API_Uri}/getListaDias`);
}

// EC62ADM - POST - ELIMINAR HORARIO
postEliminarHorario(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarHorario` , obj);
}

// EC63ADM - GET - OBTENER LISTA DE TODAS LAS SUBCATEGORIAS
getAllSubcategoria(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllSubcategoria/${idtoken}/${token}`);
}

// EC64ADM - POST - INSERTAR SUBCATEGORIA
postInsertarSubcategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarSubcategoria` , obj);
}

// EC65ADM - POST - MODIFICAR SUBCATEGORIA
postModificarSubcategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarSubcategoria` , obj);
}

// EC66ADM - POST - ELIMINAR SUBCATEGORIA
postEliminarSubcategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarSubcategoria` , obj);
}

// EC67ADM - GET - OBTENER LISTA DE TODAS LAS FECHAS ESPECIALES
getAllFechasEsp(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllFechasEsp/${idtoken}/${token}`);
}

// EC68ADM - POST - INSERTAR FECHA ESPECIAL
postInsertarFechaEsp(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarFechaEsp` , obj);
}

// EC69ADM - POST - MODIFICAR FECHA ESPECIAL
postModificarFechaEsp(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarFechaEsp` , obj);
}

// EC70ADM - POST - ELIMINAR FECHA ESPECIAL
postEliminarFechaEsp(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarFechaEsp` , obj);
}

// EC71ADM - GET - OBTENER LISTA DE CATEGORIAS
getListaCategoria(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaCategoria/${idtoken}/${token}`);
}

// EC72ADM - POST - INSERTAR PEDIDO ADM
postInsertarPedidoAdm(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarPedidoAdm` , obj);
}

postInsertarfacturaventa(obj:any) {
  return this.http.post(`${this.API_Uri}/postInsertarfacturaventa` , obj);
}
postModificarfacturaventa(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificarfacturaventa` , obj);
}
postEliminarfacturaventa(obj:any) {
  return this.http.post(`${this.API_Uri}/postEliminarfacturaventa` , obj);
}
postEliminarfacturacompra(obj:any) {
  return this.http.post(`${this.API_Uri}/postEliminarfacturacompra` , obj);
}

// EC73ADM - GET - OBTENER LISTA DE PRODUCTOS POR MARCA
getListaProductosMarcaADM(idMarca: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaProductosMarcaADM/${idMarca}/${idtoken}/${token}`);
}

// EC74ADM - GET - OBTENER LISTA DE MARCAS POR USUARIO
getListaMarcaUsuario(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaMarcaUsuario/${idtoken}/${token}`);
}

// EC75ADM - POST - VERIFICAR CUPON
postVerificarCuponADM(obj: any) {
  return this.http.post(`${this.API_Uri}/postVerificarCuponADM` , obj);
}

// EC76ADM - GET - OBTENER LISTA DE TIPOS DE PEDIDO
getListaTipoPedido(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaTipoPedido/${idtoken}/${token}`);
}

// EC77ADM - GET - OBTENER LISTA DE SUSCRIPCIONES
getListaSuscripciones(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaSuscripciones/${idtoken}/${token}`);
}

// EC78ADM - GET - OBTENER VENTAS POR PRODUCTO POR RANGO DE FECHAS
getVentasProductoRango(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getVentasProductoRango/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}

// EC79ADM - GET - RESETEAR PASSWORD
getResetPassword(idUsuario: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getResetPassword/${idUsuario}/${idtoken}/${token}`);
}

// EC80ADM - GET - OBTENER PRECIO DE TRANSPORTE MAXMIN
getPrecioTransMaxMin(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getPrecioTransMaxMin/${idtoken}/${token}`);
}

// EC81ADM - POST - MODIFICAR PRECIO TRANSPORTE MAXMIN
postModificarPrecioTransporteMaxMin(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarPrecioTransporteMaxMin` , obj);
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

getAllpromocion() {
  return this.http.get(this.API_Uri + `/getAllpromocion`);
}

getOnepromocion(id: any) {
 return this.http.get(`${this.API_Uri}/getOnepromocion/${id}`);
}

postInsertarpromocion(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarpromocion` , obj);
}

postModificarpromocion(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarpromocion` , obj);
}

postEliminarpromocion(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarpromocion` , obj);
}



////////////////////////////////////////////////////////
  getAllpublicidad() {
    return this.http.get(`${this.API_Uri}/getAllpublicidad`);
    }
  getOnepublicidad(id: any) {
    return this.http.get(`${this.API_Uri}/getOnepublicidad/${id}`);
  }
  postInsertarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarpublicidad` , obj);
  }
  postModificarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarpublicidad` , obj);
  }
  postEliminarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarpublicidad` , obj);
  }
///////////////////////////////////////////////////

getAllcalificacion() {
  return this.http.get(`${this.API_Uri}/getAllcalificacion`);
}

getOnecalificacion(id: any) {
 return this.http.get(`${this.API_Uri}/getOnecalificacion/${id}`);
}

postInsertarcalificacion(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarcalificacion` , obj);
}

postModificarcalificacion(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarcalificacion` , obj);
}

postEliminarcalificacion(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarcalificacion` , obj);
}
  // makeToast(type:NbComponentStatus, title:string, body:string) {
  //   this.showToast(type, title, body);
  // }

getAllclientes(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllclientes/${idtoken}/${token}`);
}
getAllclientes_lite(palabra: any) {
    return this.http.get(`${this.API_Uri}/getAllclientes_lite/${palabra}`);
}

getOnecliente(id:any, idtoken: any, token: any) {
   return this.http.get(`${this.API_Uri}/getOnecliente/${id}/${idtoken}/${token}`);
}

postInsertarcliente2(obj:any) {
    return this.http.post(`${this.API_Uri}/postInsertarcliente2` , obj);
}

postModificarcliente2(obj:any) {
    return this.http.post(`${this.API_Uri}/postModificarcliente2` , obj);
}

postEliminarcliente(obj:any) {
    return this.http.post(`${this.API_Uri}/postEliminarcliente` , obj);
}

getAlldepartamento(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAlldepartamento/${idtoken}/${token}`);
}

//////proveedores

getAllproveedor(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllproveedor/${idtoken}/${token}`);
}

postInsertarproveedor(obj:any) {
  return this.http.post(`${this.API_Uri}/postInsertarproveedor` , obj);
}

postModificarproveedor(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificarproveedor` , obj);
}
postModificarproveedorEstado(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificarproveedorEstado` , obj);
}



getAllproductos_lite(palabra: any) {
  return this.http.get(`${this.API_Uri}/getAllproductos_lite/${palabra}`);
}

//gastos
getAllgasto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllgasto/${idtoken}/${token}`);
}

postInsertargasto(obj:any) {
  return this.http.post(`${this.API_Uri}/postInsertargasto` , obj);
}

postModificargasto(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificargasto` , obj);
}

postModificarEstadogasto(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificarEstadogasto` , obj);
}

getAlltipo_gasto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAlltipo_gasto/${idtoken}/${token}`);
}

postEliminargasto(obj:any) {
  return this.http.post(`${this.API_Uri}/postEliminargasto` , obj);
}

//ventas

getFacturaVenta(id: any) {
  return this.http.get(`${this.API_Uri}/getFacturaVenta/${id}`);
}

//compras

getFacturaCompra(id: any) {
  return this.http.get(`${this.API_Uri}/getFacturaCompra/${id}`);
}
getAllproveedores_lite(palabra: any) {
  return this.http.get(`${this.API_Uri}/getAllproveedores_lite/${palabra}`);
}

postInsertarfacturacompra(obj:any) {
  return this.http.post(`${this.API_Uri}/postInsertarfacturacompra` , obj);
}
postModificarfacturacompra(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificarfacturacompra` , obj);
}

getAllcomprasporpagar() {
  return this.http.get(`${this.API_Uri}/getAllcomprasporpagar`);
}
getAllabonoscompras(idFactura: any) {
  return this.http.get(`${this.API_Uri}/getAllabonoscompras/${idFactura}`);
}

getAllventasporcobrar() {
  return this.http.get(`${this.API_Uri}/getAllventasporcobrar`);
}
setPagoFacturaCompra(obj:any){
  return this.http.post(`${this.API_Uri}/setPagoFacturaCompra` , obj);
}
setAbonoFacturaCompra(obj:any){
  return this.http.post(`${this.API_Uri}/setAbonoFacturaCompra` , obj);
}
setPagoFacturaVenta(obj:any){
  return this.http.post(`${this.API_Uri}/setPagoFacturaVenta` , obj);
}

//reportes 
getReporte2() {
  return this.http.get(`${this.API_Uri}/getReporte2`);
}
getReporte3(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getReporte3/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}
getReporte22() {
  return this.http.get(`${this.API_Uri}/getReporte22`);
}
getReporte23(idProducto: any) {
  return this.http.get(`${this.API_Uri}/getReporte23/${idProducto}`);
}
getReporte32(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getReporte32/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}
getReporte33() {
  return this.http.get(`${this.API_Uri}/getReporte33`);
}
getReporte41(fechaInicio: any,  idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getReporte41/${fechaInicio}/${idtoken}/${token}`);
}
getReporte42(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getReporte42/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}
getReporte43(fechaInicio: any, fechaFin: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getReporte43/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
}



postModificarconfiguracion(obj:any) {
  return this.http.post(`${this.API_Uri}/postModificarconfiguracion` , obj);
}

getOneconfiguracion(id:any) {
  return this.http.get(`${this.API_Uri}/getOneconfiguracion/${id}`);
}

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = title ? `${title}` : '';

    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
